import { API, Auth, Storage } from "aws-amplify";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { MOCK_GQL_API } from "../../components/constants";
import { UserCreditContext } from "../../components/credits/credits_context";
import { SideBarCreditComponent } from "../../components/credits/credits_msg";
import { getDoubleToken } from "../../components/labo/token";
import Layout from "../../components/layout";
import LoadingAnimation from "../../components/loading";
import {
  ImgFieldContainer,
  ResultContainer,
  ResultField,
  SeparatorClick,
  StyledRightArrowIcon,
  ExampleImg,
  UploadImgField,
  Disclaimer,
} from "../../components/pages/faces_expe";
import PageHeading from "../../components/page_heading";
import SideBar from "../../components/sidebar";
import { DynamicMessage } from "../../components/user_message";
import { getEnvName, sleep } from "../../components/utils";
import { getSignedURL } from "../../graphql/queries";
import { onCreateS3DownImage } from "../../graphql/subscriptions";
import { Instruction } from "../../components/user_message";
import { Centerer } from "../../components/utils";
import { useMsgState } from "../../components/user_message";

const FacesExpePage = () => {
  const msgRef = useRef(null);
  const [userMsg, setUserMsg] = useMsgState("", msgRef);
  const [startImgFile, setStartImgFile] = useState(null);
  const [endImgFile, setEndImgFile] = useState(null);
  const [imgKey, setImgKey] = useState(null);
  const [resultImgURL, setResultImgURL] = useState("/labo/face_filler.jpg");
  const [isWaiting, setIsWaiting] = useState(false);
  const {
    userInfo,
    isAuthenticated,
    isReadyToRetry,
    hasSufficientCredits,
    setCookieCredits,
  } = useContext(UserCreditContext);

  const environment = getEnvName();
  const uploadBucket = `fromprog-vae-upload-bucket-${environment}`;

  function handleImageLoad(event, setter) {
    const {
      target: { value, files },
    } = event;
    const fileForUpload = files[0];
    setter(fileForUpload || value);
  }

  function getStartImgName(key, extension) {
    return `${key}_start.${extension}`;
  }

  function getEndImgName(key, extension) {
    return `${key}_end.${extension}`;
  }
  function getOutputImgName(key) {
    return `${key}_output.gif`;
  }

  async function getFileToUrl(files) {
    const doubleToken = await getDoubleToken();
    const response = await API.graphql({
      query: getSignedURL,
      variables: {
        bucket: uploadBucket,
        files: files,
      },
      authToken: doubleToken,
      authMode: "AWS_LAMBDA",
    });
    if (response.data.getSignedURL.status.status_code != 200) {
      console.log("Error:",response)
      throw new Error("Non 200 code for signed url");
    }
    const fileUrls = response.data.getSignedURL.fileUrls;
    const file2Url = fileUrls.reduce(
      (obj, item) => Object.assign(obj, { [item.file]: item.url }),
      {}
    );
    return file2Url;
  }

  async function startSubscription() {
    const outputImgName = getOutputImgName(imgKey);
    const filter = { key: { eq: outputImgName } };

    const sub = API.graphql({
      query: onCreateS3DownImage,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        owner: Auth.user.attributes.sub,
        filter,
      },
    }).subscribe({
      next: async ({ value }) => {
        const {
          bucket: downloadBucket,
          key,
          status_code,
          description,
        } = value.data.onCreateS3DownImage;
        if (status_code != 200) {
          setUserMsg("error");
        } else {
          let imgUrl = await Storage.get(key, {
            bucket: downloadBucket,
            level: "private",
          });
          setResultImgURL(imgUrl);
        }
        setIsWaiting(false);
      },
      error: (error) => {
        setIsWaiting(false);
      },
    });

    return () => {
      sub.unsubscribe();
    };
  }

  useEffect(() => {
    if (imgKey) {
      startSubscription();
    }
  }, [imgKey]);

  async function sendImageToS3(imgFile, url) {
    await axios({
      method: "put",
      url: url,
      data: imgFile,
      headers: { "Content-Type": imgFile.type },
    });
  }

  function getFileName(key, imgFile, imgType) {
    const extension = imgFile.name.split(".")[1];
    switch (imgType) {
      case "start":
        return getStartImgName(key, extension);
      case "end":
        return getEndImgName(key, extension);
      default:
        throw `image type ${imgType} should be either start or end`;
    }
  }

  async function executeUploadImages() {
    try {
      const key = `${uuid()}`;
      setImgKey(key);

      const startFileName = getFileName(key, startImgFile, "start");
      const endFileName = getFileName(key, endImgFile, "end");

      const file2Url = await getFileToUrl([startFileName, endFileName]);

      const startUrl = file2Url[startFileName];
      await sendImageToS3(startImgFile, startUrl);

      const endUrl = file2Url[endFileName];
      await sendImageToS3(endImgFile, endUrl);
    } catch (err) {
      setIsWaiting(false);
      console.log("error: ", err);
      setUserMsg("error");
    }
  }

  async function mockUploadImages() {
    await sleep(1000);
    setResultImgURL(
      "https://compote.slate.com/images/697b023b-64a5-49a0-8059-27b963453fb1.gif"
    );
    setIsWaiting(false);
  }

  async function handleUploadImages() {
    if (!isAuthenticated()) {
      setUserMsg("unauthenticated");
      return;
    }
    if (!hasSufficientCredits() && !isReadyToRetry()) {
      setUserMsg("no_credits");
      return;
    }
    if (!startImgFile || !endImgFile) {
      setUserMsg(
        "You need to have load two faces before generating a face wrap"
      );
      return;
    }
    setIsWaiting(true);
    if (MOCK_GQL_API) {
      await mockUploadImages();
    } else {
      await executeUploadImages();
    }
  }

  const creditInfo = (
    <SideBarCreditComponent
      user={userInfo.user}
      credits={userInfo.credits}
      endDate={userInfo.endDate}
    />
  );

  const linkToBlogPost = (
    <p>
      To know more about the model architecture and the training procedure
      preceding it, check out <a href="/blog/faces_expe/">this link</a>
    </p>
  );
  const disclaimer = (
    <Disclaimer style={{ backgroundColor: "" }}>
      <h2>Disclaimer</h2>
      <br />
      <i>
        <b>NB</b>: currently, the model performances are suboptimal as{" "}
        <ol>
          <li>
            {" "}
            The model consists of a vanilla VAE and is therefore limited when it
            comes to model the latent space of the face images{" "}
          </li>
          <li>
            The hyperparameter space was not thoroughly explored due to
            increasing GPU-related costs (Already with the cheese image
            generator){" "}
          </li>
        </ol>
        An improved version of the model will be uploaded in the following
        months!
      </i>{" "}
      🙂
    </Disclaimer>
  );
  const instruction = (
    <Instruction>
    Upload two images of faces to warp in the <i>Upload Image</i> fields, and click on <i>Generate GIF</i>: for optimal results, your face images should:
      <ul>
        <li> Be centered vertically and horizontally <b>(the eyes should be aligned with the grey circles)</b></li>
        <li> Ideally on a clear background</li>
        <li>
          {" "}
          Respect the face to image ratio of the filler image silhouettes
        </li>
        <li> Not be blurred</li>{" "}
      </ul>
    </Instruction>
  );

  return (
    <div>
      <SideBar elements={creditInfo} />
      <Layout iconName="labo">
        <PageHeading
          headingText="Face Warping"
          subHeadingText={`A simple face warping application using Variational Autoencoders`}
        />
        <Centerer>
          <ExampleImg src="/labo/face_wrap_example.gif" />
        </Centerer>
        {linkToBlogPost}
        {disclaimer}
        {instruction}

        <DynamicMessage refProp={msgRef} msgState={userMsg} />
        <ImgFieldContainer>
          <UploadImgField
            onChange={(event) => handleImageLoad(event, setStartImgFile)}
            imgFile={startImgFile}
          />
          <StyledRightArrowIcon />
          <UploadImgField
            onChange={(event) => handleImageLoad(event, setEndImgFile)}
            imgFile={endImgFile}
          />
        </ImgFieldContainer>
        <SeparatorClick onClick={handleUploadImages} />
        <ResultContainer>
          <ResultField imgURL={resultImgURL} />
        </ResultContainer>
        {isWaiting && <LoadingAnimation nSeconds={45} nSteps={15} />}
      </Layout>
    </div>
  );
};

export default FacesExpePage;
