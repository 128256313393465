/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateS3DownImage = /* GraphQL */ `
  subscription OnCreateS3DownImage(
    $filter: ModelSubscriptionS3DownImageFilterInput
    $owner: String
  ) {
    onCreateS3DownImage(filter: $filter, owner: $owner) {
      bucket
      region
      key
      status_code
      description
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateS3DownImage = /* GraphQL */ `
  subscription OnUpdateS3DownImage(
    $filter: ModelSubscriptionS3DownImageFilterInput
    $owner: String
  ) {
    onUpdateS3DownImage(filter: $filter, owner: $owner) {
      bucket
      region
      key
      status_code
      description
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteS3DownImage = /* GraphQL */ `
  subscription OnDeleteS3DownImage(
    $filter: ModelSubscriptionS3DownImageFilterInput
    $owner: String
  ) {
    onDeleteS3DownImage(filter: $filter, owner: $owner) {
      bucket
      region
      key
      status_code
      description
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
